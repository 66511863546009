import React from "react"
import imgPortadaGuide from "@components/pageGuide/guides/guide12/image/imgPortadaGuia12.png"
import img1 from "@components/pageGuide/guides/guide12/image/img1.png"
import slider1 from "@components/pageGuide/guides/guide12/image/silder1.png"
import slider2 from "@components/pageGuide/guides/guide12/image/silder2.png"
import slider3 from "@components/pageGuide/guides/guide12/image/silder3.png"

const title = (
  <>
    <h1 className="titles-underlined-ebook ebook-ventas">
      Precios de la API de WhatsApp Business 2023
    </h1>
  </>
)

const textDescription = (
  <div>
    <p className="text-h2-ebook guide__description">
      La API de WhatsApp Business es una herramienta valiosa para empresas que
      buscan mejorar la comunicación con sus clientes. En esta guía encontrarás
      información detallada sobre los precios de la API en 2023, incluyendo las
      tarifas por mensajes enviados. Aprende cómo aprovechar al máximo esta
      herramienta sin comprometer tu presupuesto.
    </p>
  </div>
)

const linkForm = (
  <span>
    Al registrarte, aceptas los términos y condiciones de nuestra{" "}
    <a
      style={{ color: "#2C5C82", textDecoration: "none" }}
      href="https://beexcc.com/politica-seguridad-privacidad.html"
    >
      Política de Privacidad.{" "}
    </a>
  </span>
)

const textOne = (
  <p>
    Esta guía te ayudará a entender cómo funcionan los diferentes modelos de
    precios y cómo se aplican a tus necesidades empresariales. Además, te
    proporcionará información útil sobre las distintas funcionalidades que
    ofrece la API de WhatsApp Business, para que puedas seleccionar el plan que
    mejor se adapte a tus objetivos y presupuesto.
    <br /> <br />
    Si estás buscando implementar la API de WhatsApp Business en tu empresa,
    esta guía es imprescindible para comprender los costos asociados y maximizar
    su potencial. Con ella, podrás tomar decisiones informadas sobre cómo
    invertir en esta herramienta y mejorar la comunicación con tus clientes de
    manera efectiva y rentable.
  </p>
)

const data = {
  start: {
    support: "Guía | WhatsApp",
    title: title,
    description: textDescription,
    classtext: "guide__description-container",
    link: linkForm,
    href: "/ebook-8.pdf",
    brochure: "Guía-para-una-buena-experiencia-de-compra",
    img: imgPortadaGuide,
  },

  slider: {
    title: "¿Qué encontrarás?",
    underlined: "encontrarás?",
    checklist: [
      {
        key: 1,
        text: "Modelo de precios por conversaciones en 2023.",
      },
      {
        key: 2,
        text: "Tipos de conversaciones de la API de WhatsApp Business.",
      },
      {
        key: 3,
        text: "¿Cuáles son los costos de cada categoría de mensaje?.",
      },
      {
        key: 4,
        text: "¿Qué afectan los precios de la API de WhatsApp Business?",
      },
    ],
    listPictures: [
      {
        key: 1,
        img: slider1,
      },
      {
        key: 2,
        img: slider2,
      },
      {
        key: 3,
        img: slider3,
      },
    ],
  },

  description: {
    cards: [
      {
        text: textOne,
        photo: img1,
        class: true,
      },
    ],
  },
}

export default data
